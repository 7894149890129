import React from "react"

const Sliderabout = () => {
  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Careers</h1>
            <span className="sub-text">
              Excepteur sint occaecat cupidatat non proident, sunt in coulpa qui
              official
              <br />
              modeserunt mollit anim id est 20 years experience.
            </span>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <a className="active" href="index">
                  Home
                </a>
              </li>
              <li>About</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sliderabout
