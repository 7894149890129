import React from "react"

import Layout from "../components/layout"
import CareerPage from "../components/careers/index"

import Seo from "../components/seo"

const careerPage = () => (
  <Layout>
    <Seo title="Bradiance" desc="Bradiance" keywords="Bradiance" />
    <CareerPage />
  </Layout>
)

export default careerPage
